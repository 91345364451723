<script>
import {deepCopy, deepMerge} from "@/util/objects"
import {DICT_AUDIT_STATUS} from "@/util/constant"
import bjggs from "@/util/bjggs";

export default {
    data() {
        return {
            loading: false,
            form: deepCopy(this.defaultForm)
        }
    },
    props: {
        editor: {
            type: Object,
            required: true,
            validator: (value) => {
                return ['visible', 'id', 'isUpdate'].every((item) => value.hasOwnProperty(item))
            }
        }
    },
    computed: {
        isUpdate() {
            return this.editor.isUpdate || false;
        },
        title() {
            return this.titlePrefix + (this.isUpdate ? "编辑" : "添加");
        }
    },
    methods: {
        _clearValidate() {
            this.$nextTick(() => {
                this.$refs.form.clearValidate();
            });
        },
        open() {
            //验证是编辑还是添加并处理
            if (this.isUpdate) {
                return this.getUpdateService(this.editor.id).then((response) => {
                    this.form = deepMerge(this.defaultForm, response.data);
                    //清空校验
                    this._clearValidate();
                    return response;
                })
            } else {
                this.form = deepCopy(this.defaultForm);
                //清空校验
                this._clearValidate();
                return Promise.resolve({code: 200});
            }
        },
        cancel() {
            //关闭弹出框
            this.editor.visible = false;
        },
        commit() {
            this.form.auditStatus = DICT_AUDIT_STATUS.WAIT.key;
            this.save();
        },
        async save(callback) { // 通用save方法 增加回调
            this.loading = true;
            let isvalidate = false;
            try {
                await this.$refs.form.validate((valid, message) => {
                    if (valid) { // 如果校验通过则提交
                        isvalidate = true;
                    } else { // 校验不通过
                        this.$message.error("有必填项没有填写请检查！");
                        this.loading = false;
                    }
                });
            } catch (e) {
                this.loading = false;
                return;
            }
            if (!isvalidate) return;
            let api = this.isUpdate ? this.updateService : this.addService;
            const response = await api(this.form).finally(() => {
                this.loading = false; //TODO 更好的处理保存按钮加载问题
            });//执行保存方法
            if (callback && typeof callback === 'function') {
                callback(response);
            }
            //打印提示信息
            this.$message.success(response.msg);
            this.$emit("submitted", response);
            this.cancel();
        },
        //是否开启同步
        isSyncOpen() {
            return bjggs.IS_SYNC_OPEN;
        }
    }
}
</script>
